.footer__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 50px;
  background: #ffd629;
}

.footer__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 50px;

  font-size: 0.8rem;
  font-weight: 600;
}

.footer__link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-top: -10px;
  min-height: 50px;
}

.footer__link > a {
  text-decoration: none;
  font-weight: 600;
  color: #404040;
}

.footer__link > a:hover {
  color: #202020;
}
