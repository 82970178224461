html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
}

#root {
  width: 100%;
  height: 100%;
}

.root__wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  max-width: 100%;

  min-height: 100%;
  height: 100%;

  background: var(--offwhite);

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

.app-wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  max-width: 100%;

  min-height: 100%;
  height: 100%;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

.legal-consent-text {
  margin: 20px;
  max-width: 520px;

  font-size: 9pt;
  font-weight: 400;
  line-height: 12pt;
  padding: 10px;
  border-radius: 10px;

  text-align: center;

  background-color: #ffd629;
}

.noflow-x {
  overflow-x: hidden;
}

.noflow-y {
  overflow-y: hidden;
}

/* Flex Presets*/
.flex-rcc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-ccc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-rcs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.flex-ccs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.flex-rsc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.flex-csc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.flex-rss {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.flex-css {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.flex-cec {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.flex-grow {
  flex: 1;
}

/* Font */
@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/static/Montserrat-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/static/Montserrat-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/static/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/static/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/static/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/static/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/static/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/static/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../fonts/static/Montserrat-Black.ttf");
  src: url("../../fonts/static/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
