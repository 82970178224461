.landing__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  flex: 1 1;

  width: 100%;
  background: url("../../../config/img/background.png");
  background-position: center;
  background-size: cover;
}

.landing__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  margin: 100px 40px 40px 40px;

  height: calc(100% - 40px);
  width: 100%;
  max-width: 900px;
}

.landing__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;

  color: var(--main-text-color);
}

.landing__header--description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  max-width: 400px;
}

.description--title {
  padding: 20px 0 0 0;

  font-size: 18pt;
  font-weight: 800;

  margin-bottom: -30px;
}

.description--content {
  padding: 0 20px;
}

.description--content > p {
  margin: 10px 0;

  text-align: center;
  font-size: 11pt;

  margin-top: 20px;
  margin-bottom: 30px;

  color: var(--tmj-color);
}

.landing__header--picture {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  height: 100%;
}

.landing__form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 40px;

  border-radius: 3px;

  width: calc(100% - 80px);

  background: var(--tmj-background-base);
  background: var(--tmj-background-gradient);

  -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
}

.landing__confirmation-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 40px;
  margin-top: 100px;

  border-radius: 3px;

  width: calc(100% - 80px);

  background: var(--tmj-background-base);
  background: var(--tmj-background-gradient);

  -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
}

/* Tablet Display */
@media screen and (min-width: 501px) and (max-width: 768px) {
  .landing__header {
    flex-direction: column-reverse;
  }

  .landing__form {
    padding: 30px;
    width: calc(100% - 60px);
  }
}

/* Mobile Display */
@media screen and (max-width: 500px) {
  .app__container {
    margin: 0 40px;
    padding-top: 50px;

    height: 100%;
  }
  .landing__header {
    flex-direction: column-reverse;
  }

  .landing__form {
    border-radius: 0;

    padding: 30px 20px;
    width: calc(100% - 40px);
  }
}
