.form__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  margin: 0 0 15px 0;
  width: 100%;

  font-size: 10pt;
}

.form__item--label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  margin-left: 5px;

  font-size: 10pt;
  font-weight: 500;

  color: var(--theme-white-100);
}

.form__item > input {
  border: none;
  outline: none;

  padding: 5px;
  margin-top: 5px;

  border-bottom: 2px solid var(--theme-white-60);

  width: calc(100% - 10px);

  font-family: var(--main-font);
  font-size: 11pt;
  font-weight: 500;

  letter-spacing: 0.5px;

  color: var(--theme-white-100);
  background-color: rgba(0, 0, 0, 0);
}

.form__item > input:focus {
  border-bottom: 2px solid var(--theme-white-100);

  color: var(--theme-white-100);
  background-color: rgba(0, 0, 0, 0.1);
}

.csv-input {
  display: none;
}

.form__item--csv-input {
  width: 500px;
  padding: 5px;
  margin-top: 5px;

  border-bottom: 2px solid var(--light-text-color);

  font-family: var(--main-font);
  font-size: 9pt;
  font-weight: 600;

  color: var(--light-text-color);
  background-color: rgba(0, 0, 0, 0);
}

.form__item--csv-input:hover {
  cursor: pointer;
}

.item--submit {
  margin-bottom: 0 !important;
}

.item--submit > button {
  font-size: 100%;
  font-family: inherit;
  border: 0;
  padding: 10px;
  outline: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border-radius: 3px;

  font-weight: 600;
  background: var(--primary-100);
  color: var(--primary-accent-full);
}

.item--submit > button:hover {
  cursor: pointer;
  opacity: 0.9;
}

.item--submit > button:active {
  opacity: 0.7;
}

.form__item--alert {
  font-size: 8pt;
  font-weight: 500;
  line-height: 14pt;

  padding: 10px;
  border-radius: 3px;

  max-width: calc(500px - 10px);

  color: var(--white-100);
  background: var(--color-red);
}

/* Select Input */

.form__item > select {
  -webkit-appearance: button;
  -moz-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-padding-end: 0;
  -moz-padding-end: 0;
  -webkit-padding-start: 2px;
  -moz-padding-start: 2px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  border: none;
  outline: none;

  padding: 5px 0;
  margin: 5px 0 0 0;

  border-bottom: 2px solid var(--theme-white-100);

  width: calc(100%);

  font-family: var(--main-font);
  font-size: 11pt;
  font-weight: 500;

  color: var(--theme-white-100);
  background-color: rgba(0, 0, 0, 0);
}

.form__item > select > option {
  outline: none;

  font-family: var(--main-font);
  font-weight: 500;
  font-size: 9pt;

  color: #424242;
}

/* Checkboxes */
.form__item--checkboxes {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  margin: 10px 5px 0 5px;
  width: 100%;
  max-width: 800px;

  font-size: 10pt;
}

.checkbox-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-option {
  margin-right: 20px;
  margin-bottom: 10px;

  height: fit-content;
}

.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  position: relative;

  user-select: none;

  padding-top: 3px;
  padding-left: 30px;

  font-weight: 400;
  font-size: 10pt;
  line-height: 20pt;
  color: var(--theme-white-100);
}

.checkbox-container > input {
  visibility: hidden;
  width: 0;
  height: 0;
  opacity: 0;
}

.checkbox-container:hover {
  cursor: pointer;
}

.checkbox-container:hover > input ~ .checkmark {
  background: var(--theme-white-100);
  opacity: 0.9;
}

.checkbox-container > input:checked ~ .checkmark {
  background: var(--theme-white-100);
}

.checkbox-container > input:not(:checked) ~ .checkmark > .checkmark-icon {
  visibility: hidden;
}

.checkbox-container:hover > input:checked ~ .checkmark > .checkmark-icon {
  animation: pulse 1s infinite;
}

.checkmark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;

  border-radius: 8px;
  height: 30px;
  width: 30px;

  font-size: 11pt;

  color: var(--tmj-color);
  background: var(--theme-white-100);
}

/* pulse animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 500px) {
  .form__item--checkboxes {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    margin-top: 15px;
  }
}
