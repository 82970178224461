.navbar__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;

  width: 100%;

  height: 50px;
  background: var(--theme-primary);

  position: fixed;
  z-index: 1000;
}

.navbar__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;

  width: 100%;

  padding: 20px;
  color: var(--offwhite);
}

.navbar__container > a {
  text-decoration: none;
  color: unset;

  font-size: 10pt;
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: 0.2s ease-in-out;
}

.navbar__container > a:hover {
  opacity: 0.9;
  transition: 0.2s ease-in-out;
}
