.form-container {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.form-alert {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: fit-content;
  margin: 0 auto;
  padding: 10px;

  border-radius: 3px;

  text-align: center;
  font-size: 10pt;
  font-weight: 600;

  color: var(--tmj-color);
  background: var(--theme-white-100);
}

.form-section-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin: 0 auto 10px auto;

  text-align: center;
  font-weight: 600;
  font-size: 13pt;

  color: var(--theme-white-100);
}

.form-section-description {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin: 0 auto 20px auto;

  max-width: 500px;

  text-align: center;
  font-weight: 500;
  font-size: 10pt;
  line-height: 14pt;

  color: var(--theme-white-100);
}

.form-submit-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 300px;

  color: var(--theme-white-100);
}

.post-form {
  font-size: 12pt;
  font-weight: 600;
  line-height: 18pt;
}

.post-form-2 {
  font-size: 10pt;
  font-weight: 400;
  line-height: 18pt;

  margin-top: 20px;

  text-align: center;

  max-width: 500px;
}

.pf-large {
  font-size: 20pt;
  line-height: 30pt;
}

.animate-pulse {
  animation: pulse 1s infinite;
}

/* Kit Type and Size */
.form-kitselection-container {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  margin: 0 0 10px 0;
}

.form-kittype-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 50px;

  margin-bottom: 10px;

  font-size: 1rem;
  font-weight: 700;

  color: var(--theme-white-100);
}

.form-kitsize-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  padding: 0 0;

  color: var(--theme-primary);
  background: var(--offwhite);

  border-radius: 20px;
}

.form-kitsize-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1 1;

  height: 100%;

  padding: 40px 20px;
}

.form-kitsize-button:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.05);
}

.kit-selected {
  background-color: var(--theme-primary) !important;
  transform: scale(1.1);
  z-index: 2;
  color: var(--offwhite);

  border-radius: 20px;
}

.kitsize-selected:hover {
  background-color: var(--theme-primary-hover) !important;
}

.form-kitsize-button-sep {
  width: 0;
  height: 120px;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}

.form-kitsize-title {
  margin-bottom: 20px;
  font-size: 14pt;
  font-weight: 600;
}

.form-kitsize-desc-title {
  font-size: 11pt;
  font-weight: 600;
}

.form-kitsize-button > ul {
  margin: 20px 0 0 0;
  list-style-type: none;
  padding: 0;
}

.form-kitsize-desc {
  margin: 3px 0;
  font-size: 10pt;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  .form-kitsize-container {
    width: 100%;
    max-width: 280px;
    flex-direction: column !important;
  }

  .form-kitsize-button-sep {
    width: 180px;
    height: 1px;
    border-right: 1px solid rgba(0, 0, 0, 0);
    border-top: 1px solid rgba(0, 0, 0, 0.3);
  }

  .form-kitsize-button {
    width: 90%;
  }

  .btn-left {
    border-radius: 20px 20px 0 0;
  }

  .btn-right {
    border-radius: 0 0 20px 20px;
  }
}
