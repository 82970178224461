.theme-base {
  --main-font: "Montserrat", Arial, Helvetica, sans-serif;

  --main-text-color: #414142;
  --light-text-color: #707072;

  --background-color: #f4f4f4;
  --background-panel: #ffffff;

  /* Colors */
  --offwhite: #f9f8f2;
  --theme-primary: #7b3ed5;
  --theme-primary-hover: #642cb8;

  --theme-white-100: #f4f4f4;
  --theme-white-80: rgba(255, 255, 255, 0.8);
  --theme-white-60: rgba(255, 255, 255, 0.6);
  --theme-white-40: rgba(255, 255, 255, 0.4);
  --theme-white-20: rgba(255, 255, 255, 0.2);

  --tmj-color: #7b3ed5;
  --tmj-background-base: rgb(243, 125, 105);
  --tmj-background-gradient: linear-gradient(135deg, #7b3ed5 0%, #642cb8 100%);
}
