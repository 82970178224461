.step-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin: 20px 0 0 0;
}

.step-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 20px 10px 0 10px;
}

.step-button-container > span {
  margin-top: 10px;

  font-size: 10pt;
  color: var(--theme-white-100);
}

.step-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 50px;
  height: 50px;

  border-radius: 50%;

  background: var(--offwhite);

  -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
}

.step-button:hover {
  cursor: pointer;
  opacity: 0.9;
}

.step-button > svg {
  font-size: 14pt;
  color: var(--tmj-color);
}

.step-button:hover > svg {
  animation: pulse 1s infinite;
}

/* pulse animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
